import React, { useState } from 'react';
import './App.css';
import Login from './Login';
import Pannel from './Pannel';
import Menu from './Menu';
import Logs from './Logs';
import Doublons from './Doublons';

function App() {
  const [state, setState] = useState({ isConnected: false, token: '', page:'switchApp' });
  const [menuVisible, setMenuVisible] = useState(false);

function connexion(token) {
  if(token) {
  setState({ ...state, isConnected: true, token: token });
  console.log('Enregistrement state.token:', token);
  }
}
function toggleMenu() {
  console.log('toggleMenu', menuVisible);
  setMenuVisible(!menuVisible);
}
// Pour indiquer dans le state la page à afficher
function setPage(page) {
  setState({ ...state, page: page });
 // setMenuVisible(false);
}


  return (
 
    <div className="App">
      {menuVisible && <Menu toggleMenu={toggleMenu} setPage={setPage} />}
      {state.isConnected ? (
        <>
        <button onClick={toggleMenu} style={{ position: 'absolute', top: '10px', left: '10px' }}>
          ☰
        </button>
          {state.page==='switchApp' && <Pannel token={state.token}/>}
          {state.page==='logs' && <Logs token={state.token}/>}
          {state.page==='doublons' && <Doublons token={state.token}/>}
        </>
        ) : (
          <Login connexion={connexion} />
        )}

    </div>
  


  );
}

export default App;