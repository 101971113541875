
import React, { useEffect, useState} from 'react';

const Logs = ({token}) => {
  const [logs, setLogs] = useState([]);
useEffect(() => {
   // console.log('Logs Token:', token);
    fetch('/api/logs', {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + token,
        },
    })
    .then(response => response.json())
    .then(data => {
        console.log('Logs:', data);
        setLogs(data);
    })
    .catch(error => {
        console.error('Erreur:', error);
    });
  }
, [token]);



/* exemple de données retournées : 
[
  {
    "user": "jd.carre@experti.fr",
    "action": "disable",
    "timestamp": "2024-08-09T14:58:53.199Z",
    "result": [
      {
        "statut": "WebApp hors ligne"
      }
    ]
  },
  {
    "user": "jd.carre@experti.fr",
    "action": "enable",
    "timestamp": "2024-08-09T14:58:55.834Z",
    "result": [
      {
        "statut": "WebApp en ligne"
      }
    ]
  }
]

*/



  return (
    <div>
      <h2>Activation & desactivation</h2>
      <hr />
      <table>
        <thead>
          <tr>
            <th>Date</th>
            <th>Utilisateur</th>
            <th>Statut</th>
          </tr>
        </thead>
        <tbody>
          {logs.map((log, index) => (
            <tr key={index}>
              <td>{new Date(log.timestamp).toLocaleString().replace("/2024", "")}</td>
              <td>{log.user}</td>
              <td>{log.result[0].statut.replace("WebApp", "")}</td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default Logs;